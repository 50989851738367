<script lang="ts">
	import cn from 'classnames';
	import Button from '../routes/(components)/Button.svelte';
	import Heading from '../routes/(components)/Heading.svelte';
	import BlogListItem from '../routes/(components)/BlogListItem.svelte';

	export let blog: any; // @TODO:LUKAS, pokud mate otypovano, bude fajn doplnit
	export let homepage = false;
	export let type: any = undefined;
	export let heading: string | undefined = undefined;
	export let subheading: string | undefined = undefined;
	export let currentCategory: string = '';
	const commonTagCls = 'whitespace-nowrap px-2.5 py-1 self-start';
	const lightTagCls = cn(commonTagCls, 'bg-gray-geyser text-gray-shark');
	const darkTagCls = cn(commonTagCls, 'bg-gray-shark text-white');
	export let isPages: boolean = false;

	if (isPages) type = 'page';
</script>

<div class={cn('flex flex-col bg-gray-alabaster px-2.5 py-10 md:pb-20 md:pt-5', $$props.class)}>
	<div class="container mx-auto flex flex-col justify-center lgmax:!max-w-screen-sm">
		{#if heading || subheading}
			<div class="mb-10 flex w-full flex-col text-center">
				{#if heading}
					<Heading tag="h2" class="mb-6 text-2xl lg:text-3xl">{heading}</Heading>
				{/if}

				{#if subheading}
					<p class="leading-relaxed text-gray-tundora">
						{subheading}
					</p>
				{/if}
			</div>
		{/if}

		<div class="flex w-full flex-col lg:flex-row lg:gap-x-16">
			<div class={cn('w-full', !homepage && 'order-2 lg:order-1 lg:w-3/4')}>
				<!--
					<ItemSorting type="blog" class="!mt-0 mb-6 md:mb-8" />
				-->
				<div
					class={cn(
						'flex w-full flex-col gap-y-6',
						homepage ? 'lg:grid lg:grid-cols-3 lg:gap-x-7' : 'gap-y-5'
					)}
				>
					{#each blog as item}
						<BlogListItem {type} data={item} isHomepage={homepage}/>
					{/each}
				</div>

				{#if !homepage && !isPages}
					<Button
						onClick={() => console.log('loadmore')}
						title="blog"
						class="mx-auto mt-9 lg:mt-16"
						isLarge>Další články</Button
					>
				{/if}
			</div>

			{#if !homepage}
				<div class="order-1 lg:order-2 lg:flex lg:w-1/4 lg:flex-col">
					<Heading
						tag="h3"
						size="sm"
						class="mb-6 min-h-[2.6875rem] border-b-3 border-gray-concrete pb-1 md:mb-8 lgmax:hidden"
						>Najdi oblíbené téma</Heading
					>

					<Heading tag="h3" size="sm" class="mb-6 md:mb-8 lg:hidden">Blog</Heading>

					<ul class="flex flex-wrap gap-1 lgmax:mb-6">
						{#if isPages}
							<li class={currentCategory === '' ? darkTagCls : lightTagCls}>
								<a href="/scooter-tuning-jaknato/">Vše</a>
							</li>
							<li class={currentCategory === 'jak-to-funguje' ? darkTagCls : lightTagCls}>
								<a href="/scooter-tuning-jaknato?category=jak-to-funguje">Jak to funguje?</a>
							</li>
							<li class={currentCategory === 'zvysenivykonu' ? darkTagCls : lightTagCls}>
								<a href="/scooter-tuning-jaknato?category=zvysenivykonu">Zvýšení výkonu</a>
							</li>
							<li class={currentCategory === 'udrzba-opravy' ? darkTagCls : lightTagCls}>
								<a href="/scooter-tuning-jaknato?category=udrzba-opravy">Údržba a opravy</a>
							</li>
						{:else}
							<li class={currentCategory === '' ? darkTagCls : lightTagCls}>
								<a href="/blog/">Vše</a>
							</li>
							<li class={currentCategory === 'aktuality' ? darkTagCls : lightTagCls}>
								<a href="/blog?category=aktuality">Aktuality</a>
							</li>
							<li class={currentCategory === 'zajimavosti' ? darkTagCls : lightTagCls}>
								<a href="/blog?category=zajimavosti">Zajímavosti</a>
							</li>
							<li class={currentCategory === 'navody' ? darkTagCls : lightTagCls}>
								<a href="/blog?category=navody">Návody</a>
							</li>
							<li class={currentCategory === 'zvysenivykonu' ? darkTagCls : lightTagCls}>
								<a href="/blog?category=zvysenivykonu">Zvýšení výkonu</a>
							</li>
							<li class={currentCategory === 'cestovani' ? darkTagCls : lightTagCls}>
								<a href="/blog?category=cestovani">Cestování</a>
							</li>
							<li class={currentCategory === 'video' ? darkTagCls : lightTagCls}>
								<a href="/blog?category=video">Video</a>
							</li>
						{/if}
					</ul>
				</div>
			{/if}
		</div>

		{#if homepage}
			<Button href="/blog/" title="blog" class="mx-auto mt-9 lg:mt-16" isLarge>Další články</Button>
		{/if}
	</div>
</div>
